import React, { useState, useEffect } from 'react';
import {
  canoeTableProvider,
  supervisedTableProvider,
} from './reservationGrid.columns';
import { ReservationGridItem } from './reservationGridItem.component';
import { RichGrid } from '../components/table/RichGrid.component';
import { get } from '../fetch';
import { useAuthUsers, useDomainPath } from '../auth/auth.context';
import { useDomains } from '../hooks/useDomain';

const tables = {
  canoe: canoeTableProvider,
  supervised: supervisedTableProvider,
};

interface Props {
  status: CRM.ReservationStatus | undefined;
  kind: CRM.ActivityKind;
  rows: CRM.ReservationDetailed[];
  active: CRM.ReservationActive;
  isAdmin?: boolean | undefined;
}

function resolveTable(
  kind: CRM.ActivityKind, 
  status: CRM.ReservationStatus | undefined, 
  active: CRM.ReservationActive, 
  groups: CRM.Dictionary<string>, 
  domains: CRM.EntityName[], 
  domainConfig: CRM.DomainConfig | undefined,
  isAdmin: boolean | undefined,) {
  const table = tables[kind];
  if (!table) return null;

  return table(status, active, groups, domains, domainConfig, isAdmin);
}

export const ReservationGrid: React.FC<Props> = props => {
  const [groups, setGroups] = useState<CRM.Dictionary<string>>({});
  const domains = useDomains();
  const { domain } = useAuthUsers();
  const userGroupPath = useDomainPath('/user-group');

  useEffect(() => {
    get<CRM.UserGroupDocument[]>(userGroupPath).then(groups => {
      setGroups(
        groups.reduce((acc, group) => {
          acc[group._id] = group.displayName;
          return acc;
        }, {} as CRM.Dictionary<string>),
      );
    });
  }, [setGroups, userGroupPath]);

  const table = resolveTable(props.kind, props.status, props.active, groups, domains, domain, props.isAdmin);

  if (!table) return null;

  return (
    <RichGrid
      columns={table.columns}
      rows={props.rows}
      gridStyle={{maxHeight:'63vh', marginBottom:'-5vh', overflowY:'scroll', overflowX:'hidden'}}
      renderRow={reservation => (
        <div className='Grid-row'>
        <ReservationGridItem
          key={reservation._id}
          columns={table.columns}
          value={reservation}
        />
        </div>
      )}
    />
  );
};
