import React, { ChangeEvent, useCallback, useState } from 'react';
import { /*Button,*/ Grid, MenuItem, TextField } from '@material-ui/core';
import { ButtonMenu } from '../../components/ButtonMenu.component';
import { httpDelete, put } from '../../fetch';
import { useDomainPath } from '../../auth/auth.context';
import { GridItemProps, useGridItemStyles } from '../../components/GridItem';

export function UserGridItem({
  value,
  onDelete,
  onChange
}: GridItemProps<CRM.UserDocument>) {
  const classes = useGridItemStyles();
  const userPath = useDomainPath('/user');
  const [profilePicUrl, setProfilePicUrl] = useState<string | undefined>(undefined);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const handleDelete = useCallback(
    () =>
      httpDelete(`${userPath}/${value._id}`).then(
        () => onDelete && onDelete(value),
      ),
    [onDelete, userPath, value],
  );

  const handleProfilePicUrlChange = useCallback(
    (e: ChangeEvent<{ name?: string; value: unknown }>) =>
      {
        setProfilePicUrl(e.target.value as string);
      },
    [],
  );

  const handleApplyEditChanges = useCallback(
    (url: string) =>
      { console.log(url);
        put<CRM.User>(userPath+'/'+value._id, {
          displayName: value.displayName,
          login: value.login,
          userGroup: value.userGroup,
          profilePicUrl : url !== undefined ? url : value.profilePicUrl
        }).then((res) => {
          onChange && onChange(res as CRM.UserDocument); 
          setIsEditMode(false);
          setProfilePicUrl(undefined);
        });
      },
    [onChange, userPath, value],
  );

  return (
    <Grid container className={classes.root}>
      <Grid item xs={3}>
        {value.login}
      </Grid>
      <Grid item xs={3}>
        {value.displayName}
      </Grid>
      { !isEditMode && (
      <Grid item xs={5} style={{whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden'}}>
        {value.profilePicUrl || ''}
      </Grid>
      )}
      { isEditMode && (
      <Grid item xs={5} style={{whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden'}}>
      <TextField
            value={profilePicUrl !== undefined ? profilePicUrl : value.profilePicUrl}
            onChange={handleProfilePicUrlChange}
            fullWidth
      />
      </Grid>
      )}
      <ButtonMenu>
        <MenuItem 
          onClick={() => {handleApplyEditChanges(profilePicUrl || '')}}
          disabled={(isEditMode === true && profilePicUrl !== undefined) ? false : true}
        >
          Enregistrer Modifications
        </MenuItem>
        <MenuItem onClick={() => {setIsEditMode(!isEditMode)}}>{isEditMode ? 'Annuler Modifications' : 'Modifier'}</MenuItem>
        <MenuItem onClick={handleDelete}>Supprimer</MenuItem>
      </ButtonMenu>
    </Grid>
  );
}
