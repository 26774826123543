import { AuthUserGroup } from './auth.context';
import { ROLES } from '@crm/utils';

type AuthGroup = AuthUserGroup | undefined;

export const isAdmin = (group: AuthGroup) => ROLES.ADMIN === group?.role;
export const isAtLeastSeller = (group: AuthGroup) =>
  group && [ROLES.ADMIN, ROLES.SELLER].includes(group.role);
export const isAtLeastPartner = (group: AuthGroup) =>
  group && [ROLES.ADMIN, ROLES.SELLER, ROLES.PARTNER].includes(group.role);
