import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Box, Button, MenuItem, Theme } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, CloseOutlined, Menu, Rowing as RowingIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { AuthButton } from './auth/auth-button.component';
import { useAuth, useAuthUsers } from './auth/auth.context';
import './App.css';
import { isAdmin, isAtLeastPartner, isAtLeastSeller } from './auth/roles';
import AddIcon from '@material-ui/icons/Add';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme';

const useStyles = makeStyles<DefaultTheme, { isOpen: boolean }>(
  (theme: Theme) => ({
    header: {
      backgroundColor: theme.palette.grey['300'],
      position: 'fixed',
      height: '100%',
      left: 0,
      top: 0,
      overflowX: 'hidden',
      width: '200px',
      zIndex: 10,
      // display: ({ isOpen }) => (isOpen ? 'flex' : 'none'),
      [theme.breakpoints.down('sm')]: {
        display: ({ isOpen }) => (isOpen ? 'inherit' : 'none'),
      },
    },
    headerInner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: 10,
      gap: 5,
      height: 'calc(100% - 50px)',
      borderRight: `1px solid ${theme.palette.grey['500']}`,
      [theme.breakpoints.up('md')]: {
        height: '100%',
      },
    },
    open: {
      backgroundColor: theme.palette.grey['300'],
      border: `1px solid ${theme.palette.grey['500']}`,
      borderRadius: 20,
      position: 'fixed',
      left: 20,
      top: 20,
      display: 'flex',
      padding: '5px',
      zIndex: 5,

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    close: {
      position: 'fixed',
      bottom: 0,
      height: '3rem',
      width: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.grey['300'],
      borderTop: `1px solid ${theme.palette.grey['500']}`,
      zIndex: 11,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    logo: {
      pointerEvents: 'none',
      margin: '20px auto 0',
    },
    auth: {
      marginTop: 20,
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    authMobile: {
      marginBottom: '3.1rem',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    headerGroupName: {
      padding: '5px',
      border: '1px solid #a5a5a5',
      borderRadius: '4px',
      lineHeight:'0.7rem',
      marginTop: '10px',
      marginBottom: '5px',
      textAlign: 'center',
      width: '100%',
      fontSize: '12px'
    },
    subMenuActive: {
      background: '#d0d0d0',
      borderRadius: '5px',
      fontSize: '0.875rem',
    },
    subMenuInactive: {
      fontSize: '0.875rem',
      borderRadius: '5px',
      background: '#fff'
    },
    fixedMenuText: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  }),
);

export const Sidebar: React.FC = () => {
  const auth = useAuth();
  const { domain, groups } = useAuthUsers();
  const isAdminUser = isAdmin(auth.userGroup);
  const isSellerUser = isAtLeastSeller(auth.userGroup);
  const isPartnerUser = isAtLeastPartner(auth.userGroup);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const classes = useStyles({ isOpen });
  const authUserGroup = groups.find(g => g._id === auth.userGroup?.userGroupId);
  const [deploySellerMenu, setDeploySellerMenu] = useState<boolean>(false);
  const [deployAdminMenu, setDeployAdminMenu] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/reservations/in-activity':
      case '/reservations/archive':
      case '/reservations/cancelled':
      case '/reservations/wait-to-paid':
      case '/reservations/refunds':
      case '/reservations/gift-voucher':
      case '/reservations/trash':
      case '/sales':
        setDeploySellerMenu(true);
        break;
      case '/domain':
      case '/user-groups':
      case '/spots':
      case '/activities':
      case '/turnover':
        setDeployAdminMenu(true);
        break;
      default:
        break;
    }
  },[location.pathname]);

  return (
    <>
      <div className={classes.open} onClick={() => setIsOpen(true)}>
        <Menu />
      </div>
      <header className={`App-header ${classes.header}`}>
        <div className={classes.close} onClick={() => setIsOpen(false)}>
          <CloseOutlined />
        </div>
        <div className={classes.headerInner}>
          {domain?.logoUrl ? (
            <div className={classes.logo}>
              <img src={domain.logoUrl} alt={'logo'} />
            </div>
          ) : (
            <div className={classes.logo}>
              <RowingIcon className="App-icon" />
            </div>
          )}
          <Box display="flex" flexDirection="column">
            {auth?.userGroup && (
            <Box display="block" className={classes.headerGroupName} flexDirection="column">
              <label>point de vente<label/> <br/> <b>{authUserGroup?.displayName || ''}</b></label>
            </Box>)}
            {auth?.userGroup && (
              <div className={classes.auth}>
                <AuthButton />
              </div>
            )}
            {isPartnerUser && (
              <Box mb={2} mr={1}>
                <Link to="/reservations/create">
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    size="medium"
                    startIcon={<AddIcon />}
                  >
                    Réserver
                  </Button>
                </Link>
              </Box>
            )}
            {(isAdminUser || auth?.userGroup?.haveSupervisedActivitiesAccess) && (
              <Box mb={4} mr={1}>
                <Link to="/timeslots">
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    size="medium"
                    startIcon={<AddIcon />}
                  >
                    Encadrée
                  </Button>
                </Link>
              </Box>
            )}
            {(isSellerUser || isPartnerUser) && <NavLink to="/prices" className={classes.fixedMenuText}>Tarifs</NavLink>}
            {isPartnerUser && (
              <NavLink to="/reservations/futur" className={classes.fixedMenuText}>Réservations</NavLink>
            )}
            {isSellerUser && (
              <NavLink to="/reservations/active" className={classes.fixedMenuText}>Locations</NavLink>
            )}
            
            {isSellerUser && <NavLink to="/departures" className={classes.fixedMenuText}>Départs</NavLink>}
            {isSellerUser && <NavLink to="/arrivals" className={classes.fixedMenuText}>Retours</NavLink>}
            {/*(isAdminUser || auth?.userGroup?.haveSupervisedActivitiesAccess) && 
            (
            <NavLink to="/timeslots">Activités Encadrées</NavLink>
            )*/}
            {auth?.userGroup && (
              <div className={classes.authMobile}>
                <AuthButton />
              </div>
            )}
          { isPartnerUser && 
          <Box display="inline" alignItems={'center'} flexDirection="column" mt={1} mb={2}>
            <MenuItem
              className={deploySellerMenu ? classes.subMenuActive : classes.subMenuInactive}
              onClick={() => setDeploySellerMenu(!deploySellerMenu)}
            >
              Plus 
              { !deploySellerMenu && <ArrowDropDown /> }
              { deploySellerMenu && <ArrowDropUp /> }
            </MenuItem>
          </Box>
          }
          { deploySellerMenu && 
            <Box display="flex" flexDirection="column" ml={1}>
              {isPartnerUser && (
                <NavLink to="/sales">Fiche de caisse</NavLink>
              )}
              {isSellerUser && (
                <NavLink to="/reservations/in-activity" className={classes.fixedMenuText}>Activités en cours</NavLink>
              )}
              {isSellerUser && (
                <NavLink to="/reservations/archive" className={classes.fixedMenuText}>Terminées</NavLink>
              )}
              {isPartnerUser && (
                <NavLink to="/reservations/cancelled" className={classes.fixedMenuText}>Annulées</NavLink>
              )}
              {isSellerUser && (
                <NavLink to="/reservations/wait-to-paid" className={classes.fixedMenuText}>Paiements en attente</NavLink>
              )}
              {isSellerUser && (
                <NavLink to="/reservations/refunds" className={classes.fixedMenuText}>Remb. partiels</NavLink>
              )}
              {isSellerUser && (
                <NavLink to="/reservations/gift-voucher" className={classes.fixedMenuText}>Bons Cadeaux/Avoirs</NavLink>
              )}
              {isSellerUser && (
                <NavLink to="/reservations/trash" className={classes.fixedMenuText}>Corbeille</NavLink>
              )}
            </Box>
          }
          { isAdminUser && 
          <Box display="inline" alignItems={'center'} flexDirection="column" mb={2}>
            <MenuItem
              className={deployAdminMenu ? classes.subMenuActive : classes.subMenuInactive}
              onClick={() => setDeployAdminMenu(!deployAdminMenu)}
            >
              Admin 
              { !deployAdminMenu && <ArrowDropDown /> }
              { deployAdminMenu && <ArrowDropUp /> }
            </MenuItem>
          </Box>
          }
          { deployAdminMenu &&
          <Box display="flex" flexDirection="column" style={{ marginBottom: 'auto'}} ml={1}>
            {isAdminUser && <NavLink to="/domain" className={classes.fixedMenuText}>Domaine</NavLink>}
            {isAdminUser && <NavLink to="/user-groups" className={classes.fixedMenuText}>Utilisateurs</NavLink>}
            {isAdminUser && <NavLink to="/spots" className={classes.fixedMenuText}>Spots</NavLink>}
            {isAdminUser && <NavLink to="/activities" className={classes.fixedMenuText}>Activités</NavLink>}
            {isAdminUser && <NavLink to="/turnover" className={classes.fixedMenuText}>CA / Stats</NavLink>}
          </Box>
          }
          </Box>
        </div>
      </header>
    </>
  );
};
